.section-left-right-image {
  position: relative;

  .wrap-item {

    .item {
      align-items: center;
      display: flex;
      height: 100vh;
      position: absolute;
      width: 100%;
      justify-content: center;
      left: 0;

      .image {
        opacity: 0;
        width: 480px;
        position: absolute;
        min-height: 480px;
        @media screen and (max-width: 768px) {
          width: 100%;
          max-width: 360px;
        }
        @media screen and (max-width: 400px) {
          max-width: 300px;
          min-height: 300px;
        }

        img {
          width: 100%;
          min-height: 480px;
        }
      }

      .video {
        .mute {
          svg {
            width: 30px;
          }
        }

        .caption {
          svg {
            width: 30px;
          }
        }

        video {
          object-fit: cover;
        }

        .replay {
          svg {
            width: 30px;
          }
        }

      }

      .left {
        margin-left: -482px;
        text-align: left;
        padding-right: 30px;

        &.video {
          margin-left: -396px;
          padding: 0;
          z-index: 1;

          .replay {
            right: auto;
            left: 10px;
            top: 89px;
          }
        }

        @media screen and (max-width: 768px) {
          margin-left: 0 !important;
          text-align: center;
        }
      }

      .right {
        margin-left: 420px;
        text-align: left;
        padding-left: 30px;
        @media screen and (max-width: 768px) {
          margin-left: 0 !important;
          text-align: center;
        }

        &.text {
          margin-left: 473px;
        }

        &.video {
          padding: 0;
          margin-left: 392px;

          .caption {
            left: auto;
            right: 10px;
          }

          .replay {
            right: 10px;
            left: auto;
            top: 89px;
          }

          .mute {
            left: auto;
            right: 7px;
          }
        }
      }

      .text {
        max-width: 390px;
        position: absolute;
        top: 0;
        bottom: 0;
        transform: translateY(-90%);
        display: flex;
        align-items: center;
        @media screen and (max-width: 768px) {
          text-shadow: 1px 1px 2px black;
          z-index: 2;
          padding: 0 20px;
        }

      }

      .plyr__captions {
        font-size: 16px;
        padding: 5px;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }

        .plyr__caption {
          @media screen and (max-width: 480px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}