.section-graph-text-2 {
  .flex {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    max-width: 740px;
    margin: 0px auto;
    @media screen and (max-width: 768px) {
      justify-content: space-between;
    }
  }

  .item {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 30px;

    .wrap-graph {
      width: 100%;
      height: 500px;
      text-align: center;
      @media screen and(max-width: 992px) {
        height: 62vw;
      }
    }

    svg {
      max-width: 187px;
      height: auto;
      width: 30vw;
      position: relative;
      top: -20px;

      .st0 {
        opacity: 0.58;
        fill: #D9D9D9;
      }

      .st1 {
        fill: #D9D9D9;
      }

      .st2 {
        fill: #FFFFFF;
      }

      .st3 {
        font-family: 'Gotham-Bold';
      }

      .st4 {
        font-size: 18px;
      }

      .st5 {
        opacity: 0.5;
        fill: #FFFFFF;
      }
    }
  }

  .graph {
    position: absolute;
    opacity: 0;
    @media screen and (max-width: 768px) {
      img {
        max-width: 100%;
      }
    }
  }

  .text {
    position: absolute;
    max-width: 320px;
    opacity: 0;
    @media screen and (max-width: 768px) {
    }
  }
}