.section-graph-text {
  .flex {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
  }

  .text {
    max-width: 346px;
  }

  .graph {

    .title {
      margin-top: 20px;
      max-width: 375px;
      text-align: center;
      @include adaptive-font-size(1920,319,20,16);

    }

    svg {
      max-width: 100%;
      height: auto;
      rect {
        opacity: 0;
      }
    }
  }
}