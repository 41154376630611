.section-simple-text-type-2 {
  position: relative;
  overflow: hidden;

  .container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }

  .wrap-text {
  }

  .text {
    text-align: left;
    width: 100%;
    position: absolute;
    z-index: 1;
    max-width: 700px;
    margin: 0px auto;
    @media screen and (max-width: 768px) {
      max-width: calc(100% - 40px);
    }
    @media screen and (max-width: 400px) {
      br {
        display: none;
      }
    }
  }

  .image {
    margin-bottom: 40px;

    img {
      max-width: 100%;
    }
  }


  .flex {

    display: flex;
    justify-content: space-between;
    max-width: 700px;
    margin: 20px auto 0;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }

    .text {
      width: 24%;
      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
  }

  .text1 {
    width: auto;
  }
}