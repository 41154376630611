.section-simple-text {
  position: relative;
  overflow: hidden;

  .container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }

  .wrap-text {
    opacity: 0;
  }

  .text {
    padding-bottom: 20px;
    text-align: left;
    width: 100%;
    position: relative;
    z-index: 1;
    max-width: 740px;
    margin: 0px auto;
    @media screen and (max-width: 400px) {
      padding-bottom: 10px;
      br {
        display: none;
      }
    }

    a {
      img {

      }
    }
  }

  img {
    max-width: 100%;
    @media screen and (max-width: 480px) {
      max-height: 15vh;
      margin: 5px 0;
    }

  }

  .flex {
    display: flex;
    justify-content: space-between;
    max-width: 700px;
    margin: 20px auto 0;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      margin: 0;
    }

    &.step-by-step {
      max-width: 860px;
      @media screen and (max-width: 480px) {
        max-width: 100%;
      }

      p {
        max-width: 150px;
        margin: 0px auto;
        @media screen and (max-width: 480px) {
          max-width: 100%;
        }
      }
    }

    .text {
      width: 25%;
      padding: 0 1%;
      @media screen and (max-width: 480px) {
        width: 100%;
        display: flex;
        padding-bottom: 10px;
        align-items: center;
      }

      img {
        margin: 0 -4%;
        max-width: 109%;
        @media screen and (max-width: 480px) {
          margin: 0 5px 0 0;
          max-width: 30%;
          object-fit: cover;
        }
      }
    }
  }

  .text1 {
    width: auto;
  }
}