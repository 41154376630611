body {
  background: #000;
  color: #ffffff;
}

.full-height {
  height: 100vh;
}

.text {
  line-height: 1.4;
}


a {
  //display: inline-block;
  color: #2B93D1;
  text-decoration: none;
  transition: color .5s, opacity .5s, background .5s;

  &:hover {
    color: #1e729d;

    &:before {
      // width: 0;
    }
  }

  &:before {
    transition: 0.5s;
    position: absolute;
    width: 100%;
    background: #2B93D1;
    //content: "";
    left: 0;
    bottom: 0;
    height: 1px;
  }

  &:focus {
    outline: none;
  }
}

button {
  transition: color .5s, opacity .5s, background .5s;
  cursor: pointer;
}

.back-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.rect-outer {
  position: relative;

  .rect-inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.plyr {
  pointer-events: none !important;
  position: absolute;
  width: 100%;
  height: 100%;

  .plyr__poster {
    background-size: cover;
    background-position: center;
  }

  .plyr__captions {
    font-family: "Gotham-Bold", sans-serif;

    @media screen and (max-width: 768px) {
      padding: 10px 10px 64px;
    }

    .plyr__caption {
      color: #FFFFFF;
      background: none;
      text-shadow: 1px 1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, -1px -1px 0 #000, 1px 0px 0 #000, 0px 1px 0 #000, -1px 0px 0 #000, 0px -1px 0 #000;
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}

.mute {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  cursor: pointer;

  svg {
    width: 35px;
    height: auto;
  }

  line {
    transition: 0.5s;
    opacity: 0;
  }

  &.active {
    line {
      opacity: 1;
    }
  }


  line, path {
    stroke: #fff;
    fill: rgba(0, 0, 0, 0.5);

  }
}

.caption {
  cursor: pointer;
  position: absolute;
  top: 50px;
  left: 10px;
  z-index: 2;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #fff;
    transform: rotate(34deg);
    top: 15px;
    transition: 0.5s;
    opacity: 1;
  }

  svg {
    width: 33px;
    height: auto;
  }

  &.active {
    &:before {
      opacity: 0;
    }
  }
}

.replay {
  cursor: pointer;
  position: absolute;
  top: 55px;
  left: 10px;
  z-index: 2;
  opacity: 0;

  &.caption_space {
    top: 90px;
  }

  svg {
    width: 35px;
    height: auto;
    fill: #fff;
  }
}

.arrow-down-wrap {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  text-align: center;


  //&.visible {
  //  opacity: 1;
  //}
  .arrow-down-wrap-in {
    transition: opacity .4s;
    opacity: 0;
  }

  .scroll-down-text {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Gotham-Book", sans-serif;
    color: #FFF;
  }

  .arrow-down {
    position: relative;
    border: solid #FFF;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 7px;
    transform: rotate(45deg);
    animation: bounce 1s ease-in-out .25s infinite;

    &:before {
      content: '';
      border: solid #FFF;
      border-width: 0 1px 1px 0;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      margin: 2px;
      padding: 3px;
    }
  }

  @keyframes bounce {
    0% {
      bottom: 0;
    }
    50% {
      bottom: 5px;
    }

    100% {
      bottom: 0;
    }
  }

  .progress {
    font-size: 18px;
    margin-top: 8px;
    font-family: "Gotham-Book", sans-serif;
  }
}

.to_top {
  border: 1px solid #fff;
  color: #ffff;
  padding: 5px 7px;
  font-family: "Gotham-Book";
  font-size: 16px;
  width: fit-content;
  max-width: 375px;
  text-transform: uppercase;
}