@mixin animate-burger-button {
  border: none;
  background: transparent;

  &:hover {
    background: transparent;
    .animate-burger {
      .top {
       transform: translateY(-3px);
      }
      .bottom {
        transform: translateY(3px);
      }
    }
  }
  &:not(.collapsed) {
    .animate-burger {
      .top {
        transform: translateY(7px) rotate(-45deg);
      }
      .middle {
        transform: translateX(10px);
        opacity: 0;
      }
      .bottom {
        transform: translateY(-7px) rotate(45deg);
      }
    }
  }
  .animate-burger {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 16px;
    span {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #1A459C;
      left: 0;
      transition: transform .5s, opacity .6s;
    }
    .top {
      top: 0;
    }
    .middle {
      top: 50%;
      margin-top: -1px;
    }
    .bottom {
      bottom: 0;
    }
  }
}


// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-display: swap;
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}



@mixin triangle($width: 20px, $height: 20px, $direction: 'down', $color: black) {
  width: 0;
  height: 0;
  content: '';

  @if $direction == right {
    border-top: $height/2 solid transparent;
    border-bottom: $height/2 solid transparent;
    border-left: $width solid $color
  }

  // Left
  @if $direction == left {
    border-top: $height/2 solid transparent;
    border-bottom: $height/2 solid transparent;
    border-right: $width solid $color;
  }

  // Up
  @if $direction == up {
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-bottom: $height solid $color;
  }

  // Down
  @if $direction == down {
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-top: $height solid $color;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}