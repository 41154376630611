.section-video-text {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .title-box {
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px 0;
    position: relative;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
  }

  .animate-text-top {
    position: relative;
    height: 170px;
    overflow: hidden;

    h1 {
      font-size: 106px;
      line-height: 0.8;
      @include adaptive-font-size(1800, 319, 106, 76);
      padding-bottom: 15px;

    }

    .text {
      transform: translateY(100%);
      bottom: 0;
      top: 0;
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      height: 100%;
      text-align: left;
      line-height: 1.4;
      padding: 0 20px;
      flex-direction: column;
      max-width: 770px;
      left: 50%;
      margin-left: -385px;
      @media screen and (max-width: 992px) {
        width: 100vw;
        padding: 0 16%;
        br {
          display: none;
        }
      }
      @media screen and (max-width: 768px) {
        padding: 0 10%;
        margin: 0;
        max-width: 100%;
        left: 0;
      }
      @media screen and (max-width: 480px) {
        padding: 0 10px;
      }

      &.title {
        a.button {
          border: 1px solid #fff;
          color: #ffff;
          pointer-events: none;
          padding: 10px 12px;
          font-size: 20px;
          line-height: 40px;
          @include adaptive-font-size(1900, 319, 21, 16);
          letter-spacing: 0.024em;
          width: fit-content;
          max-width: 375px;
          text-transform: uppercase;
        }
      }
    }
  }

  .arrow {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    margin-left: -22.5px;
    display: flex;
    flex-direction: column;

    span {
      font-size: 12px;
      position: relative;
      left: -11px;
      top: -11px;
    }

    svg {
      fill: #fff;
      width: 45px;
      height: auto;

    }
  }

  &.section-1 {
    .animate-text-top {
      height: 250px;

      h1 {
        font-size: 65px;
        line-height: 0.9;
        @include adaptive-font-size(1800, 319, 65, 35);
      }
    }
  }

  .mute {
    display: flex;
    align-items: center;

    span {
      font-family: "Gotham-Book";
      font-size: 12px;
      margin-left: 5px;
      margin-top: 1px;
    }
  }
}