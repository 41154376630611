body{
  font-family: "Gotham-Bold", sans-serif;
  font-size: 23px;
  @include adaptive-font-size(1800,319,23,15);
}
h1{
  font-size: 96px;
}
h2{
  font-size: 80px;
}
h3{
  font-size: 70px;
}